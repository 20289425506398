/* line 10, scss/70-modules/personalization/_module.scss */
.personalization__wrapper {
	border-right: 1px solid rgb(var(--color-global-border));
	position: relative;
	width: auto;
	padding: 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__wrapper {
	border-right: 0;
	border-left: 1px solid rgb(var(--color-global-border));
}

@media only screen and (max-width: 999em) {
	/* line 10, scss/70-modules/personalization/_module.scss */
	.personalization__wrapper {
		border-right: 0;
		padding: 0 5px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .personalization__wrapper {
		border-right: 0;
		border-left: 0;
	}
}

/* line 23, scss/70-modules/personalization/_module.scss */
.m-personalization:last-child .personalization__wrapper {
	border-right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-personalization:last-child .personalization__wrapper {
	border-right: 0;
	border-left: 0;
}

/* line 29, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__menu {
	display: block;
}

/* line 32, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__menu .icon, .personalization__wrapper--grouped .personalization__menu .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .personalization__menu .icon.icon-person {
	margin-right: 0;
	display: inline-block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__wrapper--grouped .personalization__menu .icon {
	margin-right: initial;
	margin-left: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .personalization__wrapper--grouped .personalization__menu .icon {
		margin-right: 0;
	}
}

/* line 38, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__list {
	display: none;
}

/* line 41, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__list.is-open {
	display: block;
}

/* line 46, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__item {
	display: block;
	width: 100%;
}

/* line 50, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__item .personalization__link {
	display: block;
	white-space: nowrap;
	padding: 5px 10px;
}

/* line 56, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__item:hover {
	background-color: rgb(var(--color-global-primary));
}

/* line 59, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__item:hover .personalization__link {
	color: #92a2c1;
}

/* line 64, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__item.personalization__item--separator {
	display: none;
}

/* line 70, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__mobile-text {
	margin-left: 5px;
	display: inline-block;
	vertical-align: initial;
	color: rgb(var(--color-global-secondary));
	font-weight: initial;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__wrapper--grouped .personalization__mobile-text {
	margin-left: initial;
	margin-right: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .personalization__wrapper--grouped .personalization__mobile-text {
		margin-left: 0;
	}
}

/* line 79, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__mobile-text:hover {
	color: rgb(var(--color-dynamic-header-text));
}

/* line 87, scss/70-modules/personalization/_module.scss */
.personalization__menu {
	direction: initial;
	display: inline-block;
	text-align: center;
	padding: 0;
	overflow: hidden;
	color: rgb(var(--color-dynamic-header-text));
	font-size: inherit;
	box-sizing: border-box;
	vertical-align: middle;
	cursor: pointer;
	background: none;
	border: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__menu {
	unicode-bidi: bidi-override;
}

/* line 101, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--grouped .personalization__menu {
	letter-spacing: initial;
}

/* line 110, scss/70-modules/personalization/_module.scss */
.personalization__menu--display-icon {
	display: none;
}

/* line 114, scss/70-modules/personalization/_module.scss */
.personalization__menu .icon, .personalization__menu .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .personalization__menu .icon.icon-person {
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__menu .icon, .is-rtl .personalization__menu .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .is-rtl .personalization__menu .icon.icon-person {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .personalization__menu .icon, .is-rtl .personalization__menu .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .is-rtl .personalization__menu .icon.icon-person {
		margin-right: 0;
	}
}

@media only screen and (max-width: 999em) {
	/* line 87, scss/70-modules/personalization/_module.scss */
	.personalization__menu {
		border-radius: 0;
		display: block;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border: 1px solid rgb(var(--color-global-border));
		color: rgb(var(--color-dynamic-header-text));
	}
	/* line 127, scss/70-modules/personalization/_module.scss */
	.personalization__menu:hover {
		color: #92a2c1;
		background-color: rgb(var(--color-dynamic-background-accent));
		transition: background-color .25s linear;
	}
	/* line 133, scss/70-modules/personalization/_module.scss */
	.personalization__menu .icon, .personalization__menu .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .personalization__menu .icon.icon-person {
		margin-right: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .personalization__menu .icon, .is-rtl .personalization__menu .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .is-rtl .personalization__menu .icon.icon-person {
		margin-right: initial;
		margin-left: 0;
	}
}

@media only screen and (max-width: 999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .personalization__menu .icon, .is-rtl .personalization__menu .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .is-rtl .personalization__menu .icon.icon-person {
		margin-right: 0;
	}
}

@media only screen and (max-width: 999em) {
	/* line 142, scss/70-modules/personalization/_module.scss */
	.global-header .personalization__list,
	.paw-header .personalization__list {
		display: none;
	}
}

/* line 148, scss/70-modules/personalization/_module.scss */
.personalization__list.is-open {
	z-index: 99;
	text-align: left;
	right: 0;
	padding: 0 0 0 0;
	display: block;
	background-color: rgb(var(--color-global-positive));
	border: 1px solid rgb(var(--color-global-border));
	position: absolute;
	top: 100%;
	min-width: 130px;
	box-sizing: border-box;
	box-shadow: 0 5px 30px -5px rgb(var(--m-personalization-list-shadow));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__list.is-open {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__list.is-open {
	right: auto;
	left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__list.is-open {
	padding: 0 0 0 0;
}

/* line 168, scss/70-modules/personalization/_module.scss */
.personalization__item {
	vertical-align: middle;
}

@media only screen and (max-width: 999em) {
	/* line 168, scss/70-modules/personalization/_module.scss */
	.personalization__item {
		position: relative;
	}
}

@media only screen and (max-width: 999em) {
	/* line 168, scss/70-modules/personalization/_module.scss */
	.personalization__item {
		display: block;
		width: 100%;
	}
	/* line 181, scss/70-modules/personalization/_module.scss */
	.personalization__item .personalization__link {
		display: block;
		white-space: nowrap;
		padding: 5px 10px;
	}
	/* line 187, scss/70-modules/personalization/_module.scss */
	.personalization__item:hover {
		background-color: rgb(var(--color-global-primary));
	}
	/* line 190, scss/70-modules/personalization/_module.scss */
	.personalization__item:hover .personalization__link {
		color: #92a2c1;
	}
	/* line 195, scss/70-modules/personalization/_module.scss */
	.personalization__item.personalization__item--separator {
		display: none;
	}
}

/* line 200, scss/70-modules/personalization/_module.scss */
.personalization__item--separator {
	margin: 0 5px;
	color: rgb(var(--color-global-secondary));
	font-size: 0.92em;
}

/* line 211, scss/70-modules/personalization/_module.scss */
.personalization__link {
	color: rgb(var(--color-global-secondary));
	text-transform: uppercase;
	font-size: 1em;
}

/* line 235, scss/70-modules/personalization/_module.scss */
.personalization__link:hover {
	color: rgb(var(--color-global-secondary));
}

/* line 240, scss/70-modules/personalization/_module.scss */
.personalization__mobile-text {
	display: none;
	margin-top: -20px;
	text-transform: "";
}

@media only screen and (max-width: 999em) {
	/* line 240, scss/70-modules/personalization/_module.scss */
	.personalization__mobile-text {
		display: inline-block;
	}
}

/* line 255, scss/70-modules/personalization/_module.scss */
.m-myportfolio {
	font-size: 12px;
	font-size: 1rem;
	overflow: hidden;
}

@media only screen and (min-width: 48em) {
	/* line 255, scss/70-modules/personalization/_module.scss */
	.m-myportfolio {
		width: 380px;
		max-height: 75vh;
	}
}

/* line 264, scss/70-modules/personalization/_module.scss */
.m-myportfolio.opens-below {
	display: none;
}

/* line 267, scss/70-modules/personalization/_module.scss */
.m-myportfolio.opens-below.is-open {
	display: flex;
	flex-direction: column;
}

@media only screen and (max-width: 47.999em) {
	/* line 267, scss/70-modules/personalization/_module.scss */
	.m-myportfolio.opens-below.is-open {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

/* line 287, scss/70-modules/personalization/_module.scss */
.m-myportfolio .content-box__wrapper {
	margin-bottom: 0;
	border-bottom: 1px solid rgb(var(--color-global-positive));
}

/* line 291, scss/70-modules/personalization/_module.scss */
.m-myportfolio .content-box__wrapper:last-child {
	border-bottom: 0;
}

/* line 297, scss/70-modules/personalization/_module.scss */
.myportfolio__wrapper {
	background-color: rgb(var(--color-global-primary));
}

/* line 301, scss/70-modules/personalization/_module.scss */
.myportfolio__counter-bullet {
	margin-right: 10px;
	flex: 0 0 auto;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	background-color: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-positive));
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 0.834em;
	border-width: 0;
	border-color: rgb(var(--color-global-border));
	border-style: solid;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .myportfolio__counter-bullet {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .myportfolio__counter-bullet {
		margin-right: 0;
	}
}

/* line 319, scss/70-modules/personalization/_module.scss */
.myportfolio__align {
	text-align: right;
	text-transform: capitalize;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .myportfolio__align {
	text-align: left;
}

/* line 323, scss/70-modules/personalization/_module.scss */
.myportfolio__align--saved {
	flex: 1 0 auto;
}

/* line 328, scss/70-modules/personalization/_module.scss */
.myportfolio__item {
	line-height: normal;
	margin-bottom: 5px;
}

/* line 333, scss/70-modules/personalization/_module.scss */
.myportfolio__section {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

/* line 338, scss/70-modules/personalization/_module.scss */
.myportfolio__section-title {
	flex: 0 1 auto;
}

/* line 343, scss/70-modules/personalization/_module.scss */
.myportfolio__link {
	display: block;
}

/* line 346, scss/70-modules/personalization/_module.scss */
.myportfolio__link .icon, .myportfolio__link .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .myportfolio__link .icon.icon-person {
	color: rgb(var(--color-global-secondary));
}

/* line 351, scss/70-modules/personalization/_module.scss */
.content-box__title .myportfolio__link .icon, .content-box__title .myportfolio__link .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .content-box__title .myportfolio__link .icon.icon-person {
	display: none;
}

/* line 357, scss/70-modules/personalization/_module.scss */
.myportfolio__flyout {
	left: 0;
	width: 380px;
	max-width: 100vw;
	margin-top: -20px;
	display: none;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .myportfolio__flyout {
	left: auto;
	right: 0;
}

/* line 365, scss/70-modules/personalization/_module.scss */
.myportfolio__username {
	text-transform: none;
}

/* line 369, scss/70-modules/personalization/_module.scss */
.myportfolio__count {
	display: inline-block;
	min-width: 25px;
}

/* line 374, scss/70-modules/personalization/_module.scss */
.myportfolio__address {
	flex-shrink: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

/* line 382, scss/70-modules/personalization/_module.scss */
.myportfolio__search-title {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* line 386, scss/70-modules/personalization/_module.scss */
.myportfolio__price {
	flex-shrink: 0;
}

/* line 390, scss/70-modules/personalization/_module.scss */
.myportfolio__price,
.myportfolio__search-locations {
	padding-left: 10px;
	text-transform: uppercase;
	white-space: nowrap;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .myportfolio__price, .is-rtl
.myportfolio__search-locations {
	padding-left: initial;
	padding-right: 10px;
}

/* line 398, scss/70-modules/personalization/_module.scss */
.myportfolio__item--location::after {
	content: "\0029\0020";
}

/* line 403, scss/70-modules/personalization/_module.scss */
.myportfolio__search-locations {
	display: inline-block;
	max-width: 40%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* line 407, scss/70-modules/personalization/_module.scss */
.myportfolio__username--sidebar {
	color: rgb(var(--color-global-secondary));
	cursor: pointer;
}

/* line 435, scss/70-modules/personalization/_module.scss */
.myportfolio__username--sidebar:hover {
	color: rgb(var(--color-global-secondary));
}

@media only screen and (max-width: 999em) {
	/* line 463, scss/70-modules/personalization/_module.scss */
	.global-header--mobile-first .personalization__menu,
	.paw-header--mobile-first .personalization__menu {
		border: 0;
		font-size: 1.167em;
	}
	/* line 467, scss/70-modules/personalization/_module.scss */
	.global-header--mobile-first .personalization__menu .icon, .global-header--mobile-first .personalization__menu .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .global-header--mobile-first .personalization__menu .icon.icon-person,
	.paw-header--mobile-first .personalization__menu .icon,
	.paw-header--mobile-first .personalization__menu .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin
	.paw-header--mobile-first .personalization__menu .icon.icon-person {
		padding-top: 7px;
	}
	/* line 472, scss/70-modules/personalization/_module.scss */
	.global-header--mobile-first .personalization__wrapper--grouped,
	.paw-header--mobile-first .personalization__wrapper--grouped {
		padding: 0;
	}
}

/* line 479, scss/70-modules/personalization/_module.scss */
.paw-header--mobile-first .personalization__menu .icon, .paw-header--mobile-first .personalization__menu .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .paw-header--mobile-first .personalization__menu .icon.icon-person {
	padding-top: 0;
}

/* line 488, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--split .personalization__list {
	display: block;
}

/* line 491, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--split .personalization__list .personalization__item {
	display: inline-block;
	width: auto;
}

/* line 495, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--split .personalization__list .personalization__item--separator {
	display: none;
}

/* line 499, scss/70-modules/personalization/_module.scss */
.personalization__wrapper--split .personalization__list .personalization__item .icon {
	padding: 0 10px 0 10px;
	color: rgb(var(--color-dynamic-header-text));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__wrapper--split .personalization__list .personalization__item .icon {
	padding: 0 10px 0 10px;
}

/* Custom font not found */
@font-face {
	font-family: "icons";
	src: url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.woff2?afa155ab01425b5578e92934061734f4") format("woff2"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.woff?afa155ab01425b5578e92934061734f4") format("woff"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.ttf?afa155ab01425b5578e92934061734f4") format("truetype"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.svg?afa155ab01425b5578e92934061734f4#icons") format("svg");
	font-weight: normal;
	font-style: normal;
}

/* line 18, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon, .personalization__wrapper--grouped .personalization__menu--signin .icon.icon-person {
	font-family: "icons";
	display: inline-block;
	line-height: 1;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* line 38, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-404:before {
	content: "\f101";
}

/* line 41, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-410:before {
	content: "\f102";
}

/* line 44, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-acres:before {
	content: "\f103";
}

/* line 47, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-add:before {
	content: "\f104";
}

/* line 50, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-agent-wfp:before {
	content: "\f105";
}

/* line 53, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-agent:before {
	content: "\f106";
}

/* line 56, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-alert:before {
	content: "\f107";
}

/* line 59, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-appleid:before {
	content: "\f108";
}

/* line 62, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-area:before {
	content: "\f109";
}

/* line 65, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down-full:before {
	content: "\f10a";
}

/* line 68, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down-slim:before {
	content: "\f10b";
}

/* line 71, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down-triangle:before {
	content: "\f10c";
}

/* line 74, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down:before {
	content: "\f10d";
}

/* line 77, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-custom:before {
	content: "\f10e";
}

/* line 80, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-full:before {
	content: "\f10f";
}

/* line 83, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-slim:before {
	content: "\f110";
}

/* line 86, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-small:before {
	content: "\f111";
}

/* line 89, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-thin:before {
	content: "\f112";
}

/* line 92, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left:before {
	content: "\f113";
}

/* line 95, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-custom:before {
	content: "\f114";
}

/* line 98, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-full:before {
	content: "\f115";
}

/* line 101, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-slim:before {
	content: "\f116";
}

/* line 104, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-small:before {
	content: "\f117";
}

/* line 107, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-thin:before {
	content: "\f118";
}

/* line 110, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right:before {
	content: "\f119";
}

/* line 113, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-up-double-full:before {
	content: "\f11a";
}

/* line 116, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-up-full:before {
	content: "\f11b";
}

/* line 119, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-up:before {
	content: "\f11c";
}

/* line 122, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrows:before {
	content: "\f11d";
}

/* line 125, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-attention-triangle:before {
	content: "\f11e";
}

/* line 128, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-attention:before {
	content: "\f11f";
}

/* line 131, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-back:before {
	content: "\f120";
}

/* line 134, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bath-custom:before {
	content: "\f121";
}

/* line 137, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bath:before {
	content: "\f122";
}

/* line 140, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bed-custom:before {
	content: "\f123";
}

/* line 143, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bed:before {
	content: "\f124";
}

/* line 146, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bike:before {
	content: "\f125";
}

/* line 149, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-blog:before {
	content: "\f126";
}

/* line 152, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-boy:before {
	content: "\f127";
}

/* line 155, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-building:before {
	content: "\f128";
}

/* line 158, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-buildings:before {
	content: "\f129";
}

/* line 161, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-calculator:before {
	content: "\f12a";
}

/* line 164, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-calendar-2:before {
	content: "\f12b";
}

/* line 167, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-calendar:before {
	content: "\f12c";
}

/* line 170, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-car:before {
	content: "\f12d";
}

/* line 173, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-carret-down:before {
	content: "\f12e";
}

/* line 176, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-change-direction-reversed:before {
	content: "\f12f";
}

/* line 179, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-change-direction:before {
	content: "\f130";
}

/* line 182, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-checked-fill:before {
	content: "\f131";
}

/* line 185, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-checked:before {
	content: "\f132";
}

/* line 188, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-clock-slim:before {
	content: "\f133";
}

/* line 191, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-close-slim:before {
	content: "\f134";
}

/* line 194, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-close:before {
	content: "\f135";
}

/* line 197, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-cloud-search:before {
	content: "\f136";
}

/* line 200, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-collapsed:before {
	content: "\f137";
}

/* line 203, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-comment:before {
	content: "\f138";
}

/* line 206, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-comments:before {
	content: "\f139";
}

/* line 209, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-compare:before {
	content: "\f13a";
}

/* line 212, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-delete:before {
	content: "\f13b";
}

/* line 215, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-directions:before {
	content: "\f13c";
}

/* line 218, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-down:before {
	content: "\f13d";
}

/* line 221, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-draw:before {
	content: "\f13e";
}

/* line 224, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-earth:before {
	content: "\f13f";
}

/* line 227, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-edit:before {
	content: "\f140";
}

/* line 230, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-energy-certificates:before {
	content: "\f141";
}

/* line 233, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-expand-down:before {
	content: "\f142";
}

/* line 236, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-expand:before {
	content: "\f143";
}

/* line 239, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-extend:before {
	content: "\f144";
}

/* line 242, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-external-link-alt:before {
	content: "\f145";
}

/* line 245, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-external-link:before {
	content: "\f146";
}

/* line 248, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-eye-off:before {
	content: "\f147";
}

/* line 251, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-eye:before {
	content: "\f148";
}

/* line 254, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-facebook-2:before {
	content: "\f149";
}

/* line 257, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-facebook:before {
	content: "\f14a";
}

/* line 260, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-favicon-wfp:before {
	content: "\f14b";
}

/* line 263, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-filter-more-reverse:before {
	content: "\f14c";
}

/* line 266, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-filter-more:before {
	content: "\f14d";
}

/* line 269, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-filter:before {
	content: "\f14e";
}

/* line 272, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flag-market:before {
	content: "\f14f";
}

/* line 275, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flag:before {
	content: "\f150";
}

/* line 278, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flickr:before {
	content: "\f151";
}

/* line 281, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flipbook:before {
	content: "\f152";
}

/* line 284, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-floor-plan:before {
	content: "\f153";
}

/* line 287, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-full-screen:before {
	content: "\f154";
}

/* line 290, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-funnel:before {
	content: "\f155";
}

/* line 293, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gallery-view:before {
	content: "\f156";
}

/* line 296, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gallery:before {
	content: "\f157";
}

/* line 299, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gas:before {
	content: "\f158";
}

/* line 302, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gear:before {
	content: "\f159";
}

/* line 305, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-girl:before {
	content: "\f15a";
}

/* line 308, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-globe:before {
	content: "\f15b";
}

/* line 311, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-heart-full:before {
	content: "\f15c";
}

/* line 314, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-heart:before {
	content: "\f15d";
}

/* line 317, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-small:before {
	content: "\f15e";
}

/* line 320, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-value:before {
	content: "\f15f";
}

/* line 323, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-1-full:before {
	content: "\f160";
}

/* line 326, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-2-full:before {
	content: "\f161";
}

/* line 329, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-frame:before {
	content: "\f162";
}

/* line 332, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-small:before {
	content: "\f163";
}

/* line 335, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-value:before {
	content: "\f164";
}

/* line 338, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-wfp:before {
	content: "\f165";
}

/* line 341, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house:before {
	content: "\f166";
}

/* line 344, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-info:before {
	content: "\f167";
}

/* line 347, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-instagram:before {
	content: "\f168";
}

/* line 350, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-link:before {
	content: "\f169";
}

/* line 353, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-linkedin:before {
	content: "\f16a";
}

/* line 356, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-list-view-full:before {
	content: "\f16b";
}

/* line 359, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-list-view:before {
	content: "\f16c";
}

/* line 362, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-location-target:before {
	content: "\f16d";
}

/* line 365, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-location:before {
	content: "\f16e";
}

/* line 368, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-log-in-slim:before {
	content: "\f16f";
}

/* line 371, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-log-in:before {
	content: "\f170";
}

/* line 374, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail-line:before {
	content: "\f171";
}

/* line 377, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail-new:before {
	content: "\f172";
}

/* line 380, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail-slim:before {
	content: "\f173";
}

/* line 383, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail:before {
	content: "\f174";
}

/* line 386, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-map-slim:before {
	content: "\f175";
}

/* line 389, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-map-view-full:before {
	content: "\f176";
}

/* line 392, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-map-view:before {
	content: "\f177";
}

/* line 395, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-market-data:before {
	content: "\f178";
}

/* line 398, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-matterPort3dVR:before {
	content: "\f179";
}

/* line 401, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-median:before {
	content: "\f17a";
}

/* line 404, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-menu-spaced:before {
	content: "\f17b";
}

/* line 407, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-menu:before {
	content: "\f17c";
}

/* line 410, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-minus:before {
	content: "\f17d";
}

/* line 413, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-multiple-listings:before {
	content: "\f17e";
}

/* line 416, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-openhouse:before {
	content: "\f17f";
}

/* line 419, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pan:before {
	content: "\f180";
}

/* line 422, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pause-thin:before {
	content: "\f181";
}

/* line 425, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pause:before {
	content: "\f182";
}

/* line 428, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pdf:before {
	content: "\f183";
}

/* line 431, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-person-2:before {
	content: "\f184";
}

/* line 434, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-person-sign-up:before {
	content: "\f185";
}

/* line 437, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-person:before {
	content: "\f186";
}

/* line 440, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-2:before {
	content: "\f187";
}

/* line 443, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-classic-full:before {
	content: "\f188";
}

/* line 446, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-classic:before {
	content: "\f189";
}

/* line 449, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-slim:before {
	content: "\f18a";
}

/* line 452, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone:before {
	content: "\f18b";
}

/* line 455, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-photo:before {
	content: "\f18c";
}

/* line 458, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pin:before {
	content: "\f18d";
}

/* line 461, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pinterest-2:before {
	content: "\f18e";
}

/* line 464, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pinterest:before {
	content: "\f18f";
}

/* line 467, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-play-empty:before {
	content: "\f190";
}

/* line 470, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-play-round:before {
	content: "\f191";
}

/* line 473, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-play-square:before {
	content: "\f192";
}

/* line 476, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-plus:before {
	content: "\f193";
}

/* line 479, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-portfolio:before {
	content: "\f194";
}

/* line 482, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-printer:before {
	content: "\f195";
}

/* line 485, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-quote:before {
	content: "\f196";
}

/* line 488, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-radius:before {
	content: "\f197";
}

/* line 491, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-refine:before {
	content: "\f198";
}

/* line 494, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-remove:before {
	content: "\f199";
}

/* line 497, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-restaurant:before {
	content: "\f19a";
}

/* line 500, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-return:before {
	content: "\f19b";
}

/* line 503, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-ribbon:before {
	content: "\f19c";
}

/* line 506, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-rotate:before {
	content: "\f19d";
}

/* line 509, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-rss:before {
	content: "\f19e";
}

/* line 512, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-school:before {
	content: "\f19f";
}

/* line 515, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-schools:before {
	content: "\f1a0";
}

/* line 518, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-search:before {
	content: "\f1a1";
}

/* line 521, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-share:before {
	content: "\f1a2";
}

/* line 524, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-sign-up-slim:before {
	content: "\f1a3";
}

/* line 527, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-sign-up:before {
	content: "\f1a4";
}

/* line 530, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-signin:before {
	content: "\f1a5";
}

/* line 533, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-split-view:before {
	content: "\f1a6";
}

/* line 536, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-star-empty:before {
	content: "\f1a7";
}

/* line 539, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-star-half:before {
	content: "\f1a8";
}

/* line 542, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-star:before {
	content: "\f1a9";
}

/* line 545, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-stop:before {
	content: "\f1aa";
}

/* line 548, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-street-view:before {
	content: "\f1ab";
}

/* line 551, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-switch:before {
	content: "\f1ac";
}

/* line 554, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-tag:before {
	content: "\f1ad";
}

/* line 557, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-toggle-fullscreen:before {
	content: "\f1ae";
}

/* line 560, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-transit:before {
	content: "\f1af";
}

/* line 563, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-trash:before {
	content: "\f1b0";
}

/* line 566, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-tumblr:before {
	content: "\f1b1";
}

/* line 569, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-twitter:before {
	content: "\f1b2";
}

/* line 572, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-units:before {
	content: "\f1b3";
}

/* line 575, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-up:before {
	content: "\f1b4";
}

/* line 578, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-upload:before {
	content: "\f1b5";
}

/* line 581, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-vcard:before {
	content: "\f1b6";
}

/* line 584, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-video:before {
	content: "\f1b7";
}

/* line 587, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-vimeo:before {
	content: "\f1b8";
}

/* line 590, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-virtual-tour:before {
	content: "\f1b9";
}

/* line 593, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-volume-mute:before {
	content: "\f1ba";
}

/* line 596, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-volume:before {
	content: "\f1bb";
}

/* line 599, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-walk:before {
	content: "\f1bc";
}

/* line 602, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-wechat:before {
	content: "\f1bd";
}

/* line 605, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-wfp:before {
	content: "\f1be";
}

/* line 608, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-whatsapp:before {
	content: "\f1bf";
}

/* line 611, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-wordpress:before {
	content: "\f1c0";
}

/* line 614, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-world:before {
	content: "\f1c1";
}

/* line 617, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-yelp:before {
	content: "\f1c2";
}

/* line 620, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-youtube-2:before {
	content: "\f1c3";
}

/* line 623, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-youtube:before {
	content: "\f1c4";
}

/* line 626, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomin-slim:before {
	content: "\f1c5";
}

/* line 629, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomin:before {
	content: "\f1c6";
}

/* line 632, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomout-slim:before {
	content: "\f1c7";
}

/* line 635, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomout:before {
	content: "\f1c8";
}

/* line 638, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomreset:before {
	content: "\f1c9";
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icon[class*=arrow-left], .is-rtl .personalization__wrapper--grouped .personalization__menu--signin [class*=arrow-left].icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .is-rtl [class*=arrow-left].icon.icon-person, .is-rtl
.icon-custom[class*=arrow-left] {
	transform: rotate(180deg);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icon[class*=arrow-right], .is-rtl .personalization__wrapper--grouped .personalization__menu--signin [class*=arrow-right].icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .is-rtl [class*=arrow-right].icon.icon-person, .is-rtl
.icon-custom[class*=arrow-right] {
	transform: rotate(-180deg);
}

/* line 12, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
.personalization__wrapper--grouped {
	border-right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__wrapper--grouped {
	border-right: 0;
	border-left: 0;
}

@media only screen and (min-width: null) {
	/* line 12, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
	.personalization__wrapper--grouped {
		padding: 0;
	}
}

/* line 654, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.personalization__wrapper--grouped .personalization__menu--signin .icon-person.icon:before {
	content: "\f1a5";
}

/* line 25, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
.personalization__wrapper--grouped .personalization__menu {
	color: rgb(var(--color-dynamic-header-text));
	background-color: transparent;
}

@media only screen and (min-width: null) {
	/* line 25, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
	.personalization__wrapper--grouped .personalization__menu {
		padding: 10px 20px;
	}
	/* line 32, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
	.personalization__wrapper--grouped .personalization__menu:hover {
		color: #92a2c1;
		background-color: transparent;
	}
	/* line 37, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
	.personalization__wrapper--grouped .personalization__menu .icon, .personalization__wrapper--grouped .personalization__menu .personalization__menu--signin .icon.icon-person, .personalization__wrapper--grouped .personalization__menu--signin .personalization__menu .icon.icon-person {
		display: none;
	}
}

@media only screen and (max-width: 999em) {
	/* line 43, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
	.personalization__wrapper--grouped .personalization__menu .personalization__mobile-text {
		display: none;
	}
}

/* line 49, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
.personalization__wrapper--grouped .personalization__menu.personalization__menu--grouped {
	background-color: transparent;
	color: rgb(var(--color-dynamic-header-text));
}

/* line 53, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
.personalization__wrapper--grouped .personalization__menu.personalization__menu--grouped:hover {
	background-color: rgb(var(--color-dynamic-background-accent));
	color: rgb(var(--color-dynamic-header-text));
}

/* line 60, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
.personalization__wrapper--grouped .personalization__item:first-child {
	border-bottom: 1px solid rgb(var(--color-global-border));
}

/* line 64, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
.personalization__wrapper--grouped .personalization__item .personalization__link {
	padding: 10px 20px;
	line-height: 1.5;
	color: rgb(var(--color-global-secondary));
	background-color: rgb(var(--color-global-positive));
	font-weight: 600;
}

/* line 71, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
.personalization__wrapper--grouped .personalization__item .personalization__link:hover {
	background-color: rgb(var(--color-global-widget2));
	color: rgb(var(--color-global-secondary));
}

/* line 79, scss/80-themes/AffinityDefault/70-modules/personalization/module.scss */
.personalization__list.is-open {
	border: 0;
}

/*# sourceMappingURL=../../../../../true */